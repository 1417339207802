<template>
  <div class="patient-tabs-col">
    <div
      v-for="tab in filteredPatientTabs"
      :key="`patient-${tab.index}`"
    >
      <div class="d-flex justify-content-end">
        <div class="d-flex w-100 mt-2">
          <div
            class="patient-tab-item"
            @click="tab.onClick"
          >
            {{ tab.text }}
          </div>
          <b-button
            v-if="tab.text === 'Обращения'"
            v-b-tooltip.hover.bottomleft.v-light
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="appeal-btn ml-2"
            :disabled="!patientDetailInfo?.id"
            title="Создать обращение"
            @click="openAppealEditor"
          >
            <b-icon
              class="icon"
              icon="plus"
            />
          </b-button>
        </div>
      </div>
    </div>
    <div class="block-divider" />

    <div
      v-for="tab in filteredTabs"
      :key="`tab-${tab.index}`"
    >
      <div class="d-flex justify-content-end">
        <div
          class="patient-tab-item mt-2"
          @click="tab.onClick"
        >
          {{ tab.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixinRoles } from '@/mixins';
import { FEATURES_FOR_CHAT } from '@/helpers/consts';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'ChatPatientDetailTabs',
  mixins: [mixinRoles],
  props: {
    patientDetailInfo: {
      type: Object,
      default: () => ({}),
    },
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      patientTabs: [
        {
          index: 0,
          text: 'Информация о пациенте',
          showTab: true,
          onClick: this.openPatientInfoModal,
        },
        {
          index: 1,
          text: 'Консультации',
          showTab: true,
          onClick: this.openPatientConsultationModal,
        },
        {
          index: 2,
          text: 'Страховые полисы',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.insurancePolicies),
          onClick: this.openPatientPoliciesModal,
        },
        {
          index: 3,
          text: 'Обращения',
          showTab: true,
          onClick: this.openPatientAppealsModal,
        },
        {
          index: 4,
          text: 'Запись пациента в очередь',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.registerPatientInTheQueue),
          onClick: this.openPatientAppointmentModal,
        },
        {
          index: 5,
          text: 'Протоколы',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.btnProtocolInChat),
          onClick: this.openProtocolsModal,
        },
      ],
      tabs: [
        {
          index: 0,
          text: 'Лабораторные отделения',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.labDepartments),
          onClick: this.openLaboratoryBranchesModal,
        },
        {
          index: 1,
          text: 'Список клиник',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.clinicList),
          onClick: this.openClinicsModal,
        },
        {
          index: 2,
          text: 'Врачи',
          showTab: this.checkFeature(FEATURES_FOR_CHAT.doctors),
          onClick: this.openDoctorsModal,
        },
      ],
    };
  },
  computed: {
    filteredPatientTabs() {
      return this.patientTabs.filter((tab) => tab.showTab);
    },
    filteredTabs() {
      return this.tabs.filter((tab) => tab.showTab);
    },
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    openAppealEditor(index) {
      console.log(index);
      uiService.showModal(MODALS.APPEAL_EDITOR_NEW_MODAL, {
        name: 'AppealEditorModalNew',
        props: {
          personId: this.patientDetailInfo.id,
          creatingFromChat: true,
          chatAppealId: this.patientInfo.id,
        },
      });
    },
    openPatientInfoModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientInfoModal',
        props: {
          patientId: this.patientDetailInfo.id,
        },
      });
    },
    openPatientConsultationModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientConsultationModal',
        props: {
          patientId: this.patientDetailInfo.id,
        },
      });
    },
    openPatientPoliciesModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientPoliciesModal',
        props: {
          patientId: this.patientDetailInfo.id,
          patientInfo: this.patientDetailInfo,
        },
      });
    },
    openPatientAppealsModal() {
      uiService.showModal(MODALS.PATIENT_APPEALS_MODAL, {
        name: 'PatientAppealsModal',
        props: {
          patientId: this.patientDetailInfo.id,
          patient: this.patientDetailInfo,
        },
      });
    },
    openProtocolsModal() {
      if (this.patientDetailInfo !== null) {
        uiService.showModal(MODALS.PROTOCOLS_MODAL, {
          name: 'ProtocolsModal',
          props: {
            personId: this.patientDetailInfo.id,
          },
        });
      } else {
        showCustomMessage('warning', 'Ошибка', 'Выберите пациента для просмотра протоколов');
      }
    },
    openPatientAppointmentModal() {
      if (this.patientDetailInfo !== null) {
        uiService.showModal(MODALS.PATIENT_APPOINTMENT_MODAL, {
          name: 'AppealEditorModalNew',
          props: {
            patientDetails: this.patientDetailInfo,
          },
        });
      } else {
        showCustomMessage('warning', 'Ошибка', 'Выберите пациента для записи в очередь');
      }
    },
    openLaboratoryBranchesModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'LaboratoryBranchesModal',
        props: {
        },
      });
    },
    openClinicsModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicsModal',
        props: {
        },
      });
    },
    openDoctorsModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorsModal',
        props: {
          patientId: this.patientDetailInfo.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .patient-tabs-col {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      border-radius: 5px;

    .patient-tab-item {
      flex-grow: 1;
      text-align: center;
      background-color: #ebebeb;
      border-radius: 3px;
      box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
      padding: 5px 0;
      cursor: pointer;
    }
  }
  .block-divider {
    display: block;
    height: 1px;
    width: 95%;
    margin: 15px auto 10px;
    background: rgb(199, 199, 199);
  }
</style>
