<template>
  <div class="button-wrapper">
    <b-button
      id="popover"
      v-b-tooltip.hover.right.v-light
      variant="light"
      :type="$const.PRIMARY_BUTTON"
      size="sm"
      class="mr-auto p-1 border"
      :disabled="!patientDetailInfo?.id"
      title="Перевод чата"
      @click="togglePopover"
    >
      <b-icon icon="arrow-left-right" />
    </b-button>
    <b-button
      v-if="checkFeature(FEATURES_FOR_CHAT.mainMenuDevices)"
      v-b-tooltip.hover.bottomleft.v-light
      variant="light"
      :type="$const.PRIMARY_BUTTON"
      size="sm"
      class="ml-2 p-1 border"
      :disabled="!patientDetailInfo?.id"
      title="Устройства"
      @click="openPatientDeviceModal"
    >
      <b-icon
        icon="display"
      />
    </b-button>
    <b-button
      v-b-tooltip.hover.bottomleft.v-light
      variant="light"
      :type="$const.PRIMARY_BUTTON"
      size="sm"
      class="ml-2 p-1 border"
      :disabled="!patientDetailInfo?.id"
      title="Написать смс"
      @click="openSmsSendModal"
    >
      <b-icon icon="chat-left-dots" />
    </b-button>
    <b-button
      v-if="checkFeature(FEATURES_FOR_CHAT.call)"
      v-b-tooltip.hover.bottomleft.v-light
      variant="light"
      :type="$const.PRIMARY_BUTTON"
      size="sm"
      class="ml-2 p-1 border"
      :disabled="!patientDetailInfo?.id || !patientDetailInfo?.phoneNumber"
      title="Вызов пациента"
      @click="makeCall"
    >
      <b-icon icon="telephone" />
    </b-button>

    <b-popover
      :show.sync="isPopoverOpen"
      target="popover"
      triggers="click"
      placement="bottomright"
    >
      <template #title>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="popover-close-btn"
            @click="isPopoverOpen = false"
          >
            <b-icon icon="x" />
          </b-button>
        </div>
      </template>
      <div class="crm-popover pr-2">
        <div class="crm-popover-inner">
          <div class="d-flex justify-content-between align-items-baseline">
            <div class="crm-label">
              Роль перевода
            </div>
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="popover-btn"
              @click="moveToTheSameDepartment"
            >
              В тот же отдел
            </b-button>
          </div>
          <v-select
            ref="selectRole"
            v-model="role"
            :reduce="role => role.id"
            :options="roles"
            placeholder="Выберите роль"
            label="titleName"
            :clearable="true"
            class="crm-select"
          />
        </div>
        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          class="transfer-btn"
          size="sm"
          :disabled="!role"
          @click="chatAppealMove"
        >
          <span>Перевести роль</span>
        </b-button>
      </div>
    </b-popover>
  </div>
</template>
<script>
import { mixinRoles } from '@/mixins';
import { FEATURES_FOR_CHAT } from '@/helpers/consts';

export default {
  name: 'ChatPatientDetailInfoButtons',
  mixins: [mixinRoles],
  props: {
    patientDetailInfo: {
      type: Object,
      default: () => ({}),
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isPopoverOpen: false,
      role: null,
      FEATURES_FOR_CHAT,
    };
  },
  watch: {
    patientDetailInfo() {
      if (this.isPopoverOpen) this.clearPopover();
    },
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    async togglePopover() {
      this.isPopoverOpen = !this.isPopoverOpen;

      await this.$nextTick();
      if (this.isPopoverOpen) this.$refs.selectRole.open = true;
    },
    clearPopover() {
      this.role = null;
    },
    moveToTheSameDepartment() {
      this.$emit('moveToTheSameDepartment');
      this.togglePopover();
      this.clearPopover();
    },
    chatAppealMove() {
      this.$emit('chatAppealMove', this.role);
      this.togglePopover();
      this.clearPopover();
    },
    openPatientDeviceModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PatientDeviceModal',
        props: {
          personId: this.patientDetailInfo?.id,
          fio: `${this.patientDetailInfo?.lastName} ${this.patientDetailInfo?.firstName} ${this.patientDetailInfo?.middleName}`,
        },
      });
    },
    openSmsSendModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsSendModal',
        props: {
          numberProp: this.patientDetailInfo?.phoneNumber,
          name: `${this.patientDetailInfo?.lastName} ${this.patientDetailInfo?.firstName} ${this.patientDetailInfo?.middleName}`,
        },
      });
    },
    async makeCall() {
      await this.$sipService.makeCall(
        this.patientDetailInfo.phoneNumber,
        {
          callerId: this.$store.state.Auth.user.userId,
          callType: 'SIP',
          members: [{
            id: this.patientDetailInfo.id,
            firstName: this.patientDetailInfo.firstName,
            lastName: this.patientDetailInfo.lastName,
            middleName: this.patientDetailInfo.middleName || '',
            fullName: `${this.patientDetailInfo?.lastName} ${this.patientDetailInfo?.firstName} ${this.patientDetailInfo?.middleName}`,
            type: 'Patient',
          }],
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
 .button-wrapper {
    display: flex;
    padding-top: 15px;
  }
  .crm-popover {
  display: flex;
  flex-direction: column;

  width: 270px;
  height: 300px;
  .crm-popover-inner {
    padding-right: 10px;
  }
  .transfer-btn{
    margin-top: auto;
    max-width: 150px;
    align-self: center
  }
}
.popover-close-btn{
  border: 1px solid grey;
  padding: 0;
}
.popover-btn{
  font-size: 10px;
}

.select{
  border: 1px solid black;
}
</style>
