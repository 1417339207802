<template>
  <div class="fio-wrapper">
    <div class="fio">
      <span>Ф:</span>
      <span class="patient">{{ patientDetailInfo?.lastName }}</span>
    </div>
    <div class="fio">
      <span>И:</span>
      <span class="patient">{{ patientDetailInfo?.firstName }}</span>
    </div>
    <div class="fio">
      <span>О:</span>
      <span class="patient">{{ patientDetailInfo?.middleName }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChatPatientFio',
  props: {
    patientDetailInfo: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
<style lang="scss" scoped>
    .fio-wrapper {
      display: flex;
      flex-direction: column;

      .fio {
        display: flex;
        flex-wrap: wrap;

        span {
          font-size: 16px;
          font-weight: 500;
        }

        .patient {
          font-size: 16px;
          font-weight: 600;
          padding-left: 4px;

        }
      }
    }

</style>
