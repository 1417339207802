<template>
  <div
    class="patient-info"
    @click="onClick"
  >
    <div class="patient-info-status">
      <img
        v-if="typeOfAppeal !== null"
        :src="PROJECT_TYPES[typeOfAppeal]?.logo"
        alt="#"
        class="patient-info__project-type-img"
      >
    </div>
    <span>{{ patientFio(patient) }}</span>
    <div
      v-if="takeOfWork"
      class="patient-info-work"
      :class="{'returned-color': сomparisonAppealReturnedToLine}"
      @click="onClickTakeOn"
    >
      {{ сomparisonAppealReturnedToLine? 'Перевод': 'В работу' }}
    </div>
  </div>
</template>
<script>
import { PROJECT_TYPES } from '@/helpers/consts';

export default {
  name: 'ChatPatientInfoItem',
  props: {
    patient: {
      type: Object,
      required: true,
    },
    takeOfWork: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PROJECT_TYPES,
    };
  },
  computed: {
    typeOfAppeal() {
      switch (this.patient?.personInfo?.projectType) {
        case 'Doctis':
          return 0;
        case 'RTHealth':
          return 1;
        default:
          return null;
      }
    },
    сomparisonAppealReturnedToLine() {
      return this.patient.chatAppealStatus.includes('ReturnedToLine');
    },

  },
  methods: {
    patientFio(patient) {
      let { lastName, firstName, middleName } = patient.personInfo;
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
      firstName = `${firstName.charAt(0).toUpperCase()}.`;
      if (middleName !== null) { middleName = `${middleName.charAt(0).toUpperCase()}.`; }
      return `${lastName} ${firstName} ${middleName || ''}`;
    },
    onClick(e) {
      this.$emit('click', e);
    },
    onClickTakeOn(e) {
      this.$emit('take-on', e);
    },
  },
};
</script>
<style lang="scss" scoped>
  .patient-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 10px;
    margin: 0 5px;
    border-bottom: 1px solid rgb(228, 225, 225);
    &-status {
      display: flex;
      align-items: center;
      min-width: 25px;
    }
    &__project-type-img{
      height: 17px;
      width: 17px;
      border-radius: 4px;
    }
    span {
      font-weight: 600;
      color: #7c7575;
    }
    &-name{
      font-size: 16px;
      font-weight: 400;
      color: #c2bebe;
      margin: 10px 0;
      word-break:break-all;
    }

    &-work {
      width: 25%;
      font-size: 12px;
      text-align: center;
      border-radius: 5px;
      color:white;
      background-color: #108a03;
      padding: 0 5px;
      margin-left: auto;
      right: 10%;
      top: 10px;
    }

    .info-count-wrapper {
      flex-shrink: 0;
      width: 40px;
    }
    .info-count{
      font-size: 12px;
      padding: 0 5px;
      background-color: #007ef5;
      border-radius: 40%;
      color:white;
      margin-left: auto;
      width: min-content;
    }
  }
  .returned-color{
    background-color: rgb(255, 153, 0);
  }
</style>
